.profile-main-div {
  background-color: var(--layout);
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}
.profile-edit {
  position: relative;
}
.profile-edit-2 {
  position: absolute;
  bottom: 0;
  right: 20px;
}
.profile-img {
  height: 200px;
  width: 200px;
  border-radius: 100px;
}
.profile-title {
  font-size: 24px;
  font-weight: 700;
}
.profile-loading {
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-over-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.profile-save-btn {
  background-color: var(--primary);
  height: 52px;
  color: white;
  margin-top: 40px;
  margin-bottom: 20px;
}
@media (max-width: 760px) {
  .profile-over-div {
    padding-inline: 10px;
  }
}
.selected-item .ant-input {
  border-color: var(--primary);
}
.selected-item .ant-select-selector {
  border-color: var(--primary) !important;
}
.selected-item .ant-select .ant-select-arrow {
  color: var(--primary);
}

.error {
  color: red;
  margin-top: 0px;
}
.profile-back {
  display: flex;
  font-size: 30px;
  padding: 0px;
}
