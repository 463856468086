.table-card-main-div {
  border-bottom: 1px solid #e6ebec;
  padding-block: 10px;
}
.resp-table-card {
  /* width: 324px; */
  background-color: var(--layout);
  padding: 10px;
  display: none;
}
@media (max-width: 720px) {
  .resp-table-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 24px;
    padding-inline: 10px;
  }
}
.margin {
  margin: 24px;
}
.no-data-card {
  background-color: var(--layout);
  height: 100%;
  /* width: 100%; */
  margin-block: 24px;
  margin-inline: 24px;
  padding: 10px;
}
