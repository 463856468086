.login-page-div-1 {
  padding-inline: 200px;
  width: 100%;
}
.login-page-title-1 {
  font-size: 32px;
  font-weight: 700;
  text-align: start;
  margin-bottom: 10px;
  color: var(--title-text);
}
.login-page-title-2 {
  font-weight: 400;
  font-size: 14px;
  text-align: start;
  margin-bottom: 20px;
  color: var(--title-text);
}
.login-btn {
  width: 100%;
  /* background-color: var(--primary);
  color: var(--secondary);
  font-size: 14px;
  font-weight: 700; */
  height: 56px;
}
.login-input.ant-input {
  height: 40px;
  background-color: var(--input-color) !important;
}
.login-inputs .ant-input {
  height: 32px;
  background-color: var(--input-color) !important;
}
.forgot-password-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 600;
}
.login-inputs.ant-input-affix-wrapper .ant-input-suffix {
  display: flex;
}
@media (max-width: 999px) {
  .login-page-div-1 {
    padding-inline: 50px;
  }
}
@media (max-width: 768px) {
  .login-page-div-1 {
    padding-inline: 0px !important;
  }
}
