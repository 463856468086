.overview-title {
  font-size: 32px;
  font-weight: 700;
  padding: 10px;
}
.mt-24px {
  margin-top: 24px;
}
.h-300 {
  height: 400px;
  background-color: var(--layout);
  border-radius: 16px;
  padding: 10px;
}
.activity-status {
  background-color: var(--layout);
  min-height: 570px;
  overflow-y: auto;
  padding: 10px;
  border-radius: 8px;
}
.sales-percentage-div {
  background-color: var(--layout);
  padding: 10px;
  border-radius: 10px;
}
/* .sales-target-title {
  text-align: start;
  display: flex;
  justify-content: flex-start;
} */
.percentage-placeholder {
  font-size: 14px;
  color: var(--placeholder);
}
.percentage-title {
  font-size: 32px;
  font-weight: 700;
}
.sales-report-title {
  font-size: 18px;
  font-weight: 700;
}
.sales-target-title {
  font-size: 16px;
  font-weight: 600;
  opacity: 70%;
}
.actvity-subtitle {
  font-size: 12px;
  font-weight: 400;
  color: var(--placeholder);
}
.actvity-subtitle-highlight {
  font-size: 14px;
  font-weight: 700;
}
.activity-select .ant-select-selector {
  background-color: var(--layout) !important;
}

@media (max-width: 760px) {
  .resp-card-1 {
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 760px) {
  .resp-card {
    flex-direction: column;
  }
}
.overview-relative {
  position: relative;
}
.overview-absolute {
  position: absolute;
}
.h-250 {
  height: 250px;
}
