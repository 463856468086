.notification-over-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
  flex-direction: column;
}
.notification-main-div {
  background-color: white;
  width: 630px;
  padding: 20px;
  border-radius: 10px;
}
.notification-title-2 {
  font-size: 14px;
  font-weight: 700;
}
.notification-title-1 {
  font-size: 14px;
  font-weight: 400;
  color: var(--placeholder);
}
.notification-title-3 {
  font-size: 24px;
  font-weight: 700;
}
.notification-title-4 {
  font-size: 12px;
  font-weight: 700;
  color: var(--placeholder);
}
.notification-mt24 {
  margin-bottom: 20px;
}
.notification-cursor {
  cursor: pointer;
  background-color: var(--layout);
  padding: 10px;
}
.notification-cursor-read {
  cursor: pointer;
  background-color: white;
  padding: 10px;
}
.notification-cursor:hover {
  background-color: transparent;
  opacity: 0.5;
}
.notification-cursor-read:hover {
  background-color: transparent;
  opacity: 0.6;
}
.notification-height {
  height: 700px;
  overflow-y: auto;
}
@media (max-width: 760px) {
  .notification-over-div {
    align-items: unset;
    padding: 10px;
  }
}
@media (max-width: 760px) {
  .notification-main-div {
    width: 100%;
  }
}
