:root {
  --primary: #043843;
  --secondary: #fafafa;
  --input-color: #f9fdfd;
  --header: #f9fdfd;
  --filter-btn-color: #0000000d;
  --placeholder: #9b959f;
  --layout: #f9fdfd;
  --grey-bg: #0000000d;
  --footer: #8ca3a9;
  --title-text: #02181c;
  --bg-color: #f4f7f9;
  --btn-text-clr: #edf9f9;
  --btn-with-icon-bg: #d9d9d9;
  --border-gray: #e6ebec;
  --orange: #f3a127;
}
