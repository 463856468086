.sales-detail-btn-clr {
  background-color: var(--primary);
  color: white;
  height: 44px;
}
.sales-detail-btn-clr-2 {
  background-color: white;
  color: var(--primary);
  border: 1px solid var(--primary);
}
.sales-detail-border {
  border: 1px solid #e6ebec;
  padding: 10px;
}
.sales-detail-placeholder {
  color: var(--placeholder);
  font-size: 12px;
  font-weight: 700;
}
.sales-detail-sub-title {
  color: var(--title-text);
  font-size: 14px;
  font-weight: 700;
}
.sales-detail-border-main {
  border: 1px solid #e6ebec;
}
.p5 {
  padding: 10px;
}
.sales-detail-title {
  font-size: 18px;
  font-weight: 700;
  margin-block: 10px;
}
.sales-follow-up {
  font-size: 16px;
  font-weight: 600;
}
.sales-detail-title-2 {
  font-size: 18px;
  font-weight: 700;
}
.img-align-self {
  align-self: flex-start;
}
.break-all {
  word-break: break-all;
}
@media (max-width: 500px) {
  .p5 {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
  }
}
