.sales-title {
    padding: 10px;
    margin-inline: 24px;
  }
  .mt-24 {
    margin-top: 10px;
  }
  .download-btn-color {
    background-color: var(--filter-btn-color);
    display: flex;
    align-items: center;
  }
  .p-10 {
    padding: 10px;
    background-color: var(--header);
    margin-inline: 24px;
    border-radius: 10px;
  }
  .primary-btn {
    background-color: var(--primary);
    color: var(--secondary);
    height: 44px;
    margin-right: 24px;
  }
  .sale-profile {
    height: 100px;
    width: 100px;
  }
  .sale-profile-gap {
    margin-inline: 5rem;
  }
  .sale-profile-gap-1 {
    margin-left: 8rem;
  }
  .sale-profile-main-div {
    background-color: var(--layout);
    padding: 10px;
    margin-block: 24px;
  
    border-radius: 10px;
  }
  .sales-profile-p-10 {
    padding: 24px;
  }
  .sales-profile-title-1 {
    font-size: 18px;
    font-weight: 700;
  }
  .sales-profile-title-2 {
    font-size: 13px;
    font-weight: 400;
    color: var(--placeholder);
  }
  .sales-profile-title-3 {
    font-size: 14px;
    font-weight: 700;
  }
  @media (max-width: 720px) {
    .resp-table {
      display: none;
    }
  }
  @media (max-width: 720px) {
    .search-resp {
      display: none;
    }
  }
  @media (min-width: 720px) {
    .resp-search {
      display: none;
    }
  }
  /* .drawer-width {
    width: 500px !important;
  }
  @media (max-width: 720px) {
    .drawer-width {
      width: 100% !important;
    }
  } */
  