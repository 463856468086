.wealay-primary-btn {
  font-weight: 700;
  color: var(--btn-text-clr);
  background-color: var(--primary);
  font-size: 14px;
}
.wealay-secondary-btn {
  font-weight: 700;
  color: var(--primary);
  background-color: var(--layout);
  font-size: 14px;
  border: 1px solid var(--primary);
}
.wealay-secondary-btn:hover {
  font-weight: 700;
  color: var(--primary) !important;
  background-color: var(--layout) !important;
  font-size: 14px;
  border: 1px solid var(--primary) !important;
}
.wearlay-without-bg {
  font-size: 14px;
  color: var(--primary);
}
.wearlay-with-icon {
  background-color: var(--filter-btn-color);
  align-items: center;
  display: flex;
}
