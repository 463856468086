.create-quotation-title-1 {
  font-size: 12px;
  font-weight: 700;
  color: var(--placeholder);
}
.create-quotations-title-2 {
  font-size: 14px;
  font-weight: 700;
  color: var(--title-text);
}
.create-quotation-title-3 {
  font-size: 24px;
  font-weight: 700;
}
.create-quotation-border {
  border: 1px solid #e6ebec;
  padding: 10px;
}
.create-quotation-border-no-padding {
  border: 1px solid #e6ebec;
}
.create-quotation-div-2 {
  background-color: var(--layout);
}
.create-quotation-btn {
  height: 52px;
  background-color: var(--primary);
  color: white;
}
.grand-total {
  background-color: var(--primary);
  color: white;
  font-size: 14px;
  font-weight: 700;
}
.create-quotation-margin-top {
  margin-top: 24px;
}
.create-quotation-margin-inline {
  margin-block: 24px;
}
.create-quotation-checkbox {
  background-color: var(--layout);
  /* padding: 10px; */
}
.create-quotation-text-color {
  color: var(--placeholder);
}
.create-quotation-margin-bottom {
  margin-bottom: 24px;
}
.create-quotation-margin-top {
  margin-top: 24px;
}
.create-quotation-border-10 {
  border: 1px solid #e6ebec;
  padding: 4px;
}
.modal-submit-button .ant-btn-primary {
  background-color: var(--primary);
}
.close-checkbox-1 {
  color: red;
  font-weight: 600;
}
.are-you-sure {
  color: var(--placeholder);
  font-weight: 600;
}
.ant-modal .ant-modal-title {
  font-weight: 700;
  font-size: 18px;
}
