.customer-preview-h1 {
  height: 44px;
}
@media (max-width: 500px) {
  .customer-button {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
