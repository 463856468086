.dashboard-title-1 {
  font-size: 32px;
  font-weight: 700;
}
.dashboard-card {
  background-color: var(--primary);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 10px;
  /* height: 110px; */
  text-align: start;
}
@media (max-width: 929px) {
  .dashboard-card {
    height: 100%;
  }
}
/* @media (max-width: 768px) {
  .dashboard-card {
    text-align: start;
  }
} */
