.createlead-main-div {
  background-color: var(--layout);
  padding: 10px;
}
.createlead-title {
  /* font-weight: 700;
  font-size: 18px; */
  margin-bottom: 24px;
}
.createlead-button {
  background-color: var(--primary);
  color: white;
  width: 168px;
  height: 44px;
}
.createlead-title-1 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 24px;
}
.close-button-item {
  padding: 5px;
}
.create-lead-loader-bg {
  background-color: var(--layout);
  height: 90vh;
}
.create-table-height {
  height: 33px;
}
@media (max-width: 600px) {
  .item-table-width {
    min-width: 400px;
    overflow-x: auto;
  }
}
@media (max-width: 600px) {
  .child-item-table-width {
    min-width: 1400px;
    overflow-x: auto;
  }
}
