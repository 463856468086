.col-1-bg-image {
  background-image: url("../../../public/assets/loginbg-img.svg");
  background-size: cover;
  height: 100vh;
  background-color: var(--primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.col-2 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--bg-color);
}
.footer-login {
  color: white;
  padding: 10px;
}
.logo-img {
  padding: 10px;
  width: 92%;
}
@media (max-width: 768px) {
  .col-1-bg-image {
    display: none;
  }
}
@media (max-width: 768px) {
  .mob-col-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: var(--bg-color);
  }
  .mob-div-1 {
    display: flex !important;
    color: var(--footer);
  }
  .mob-div-2 {
    display: flex !important;
    width: 100%;
  }
}
.mob-div-1 {
  display: none;
}
.mob-div-2 {
  display: none;
}
