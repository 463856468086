.searchbox-style {
  display: flex;
  align-items: center;
  background: var(--header) !important;
  padding-left: 10px;
  border: 1px solid #e6ebec;
}
.input {
  border: none;
  /* border-radius: 8px; */
  background: var(--header) !important;
}
.input .ant-input-focused {
  border: none;
}
.cursor-pointer {
  padding: 10px;
}
.searchbox-icon {
  cursor: pointer;
}
