body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Inter", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "inter";
  src: url("../public/inter.ttf");
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.h-full {
  height: 100%;
}
.h-full-vh {
  height: 100vh;
}
.w-full {
  width: 100%;
}
.w-content {
  width: fit-content;
}
.gap-2 {
  gap: 10px;
}
.text-start {
  text-align: start;
}
.font-sm {
  font-size: 14px;
}
.flex-col {
  flex-direction: column;
}
.p-1 {
  padding: 10px;
}
.mt-24p {
  margin-top: 24px;
}
.m-inline-24 {
  margin-inline: 24px;
}
