.avatar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
}
.badge-icon {
  width: 50px;
  height: 50px;
}
.badge-div {
  cursor: pointer;
}
.dropdown-click {
  cursor: pointer;
}
.gap-34 {
  gap: 16px;
}
@media (max-width: 575px) {
  .user-cursor-pointer {
    display: none;
  }
}
.header-img {
  display: none;
}
@media (max-width: 575px) {
  .header-img {
    display: flex;
  }
}
@media (max-width: 575px) {
  .avatar {
    justify-content: space-between;
  }
}
@media (max-width: 575px) {
  .header-toggle {
    display: flex;
  }
}
.header-toggle {
  display: none;
}
