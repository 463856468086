.quotation-title {
  font-size: 32px;
  font-weight: 700;
  padding: 10px;
  margin-inline: 24px;
}
.mt-24 {
  margin-top: 10px;
}
@media (max-width: 480px) {
  .quotation-option {
    display: none;
  }
}
@media (max-width: 480px) {
  .quotation-option-2 {
    display: flex !important;
  }
}
.quotation-option-2 {
  display: none;
}
.pdf-download-button.ant-btn {
  padding: 0px;
  width: 100%;
}
.downlaod-pdf {
  padding: 10px;
}
