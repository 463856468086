.emp-profile-pic {
  height: 70px;
  width: 70px;
  border-radius: 50px;
}
.emp-profile-main-div {
  width: fit-content;
  background-color: var(--layout);
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-block: 24px;
  margin-inline: 10px;
  cursor: pointer;
}
.emp-outer-main-div {
  padding: 24px;
  /* margin-top: 24px; */
}
.emp-profile-title {
  font-size: 24px;
  font-weight: 700;
}
.emp-profile-title-1 {
  font-size: 16px;
  font-weight: 600;
}
.emp-profile-title-2 {
  font-size: 13px;
  font-weight: 400;
  color: var(--placeholder);
}
.emp-profile-title-3 {
  font-size: 13px;
  font-weight: 400;
}
.gray-text {
  color: var(--footer);
}
@media (max-width: 1169px) {
  .employee-resp-search {
    flex-direction: column;
    gap: 10px;
  }
}
.employee-resp-contacts {
  background-color: var(--layout);
  padding: 10px;
}
.employee-detail-page-main-div {
  background-color: var(--layout);
  padding: 10px;
  margin-block: 24px;
  border-radius: 10px;
}
.customer-preview-h2 {
  height: 33px;
}
.customer-back-button {
  display: flex;
  font-size: 33px;
}
.emp-height {
  height: 400px;
  /* overflow-y: auto;
  overflow-x: hidden; */
}
.customer-no-data {
  height: 80vh;
}
@media (max-width: 1169px) {
  .employee-detail-page-main-div {
    margin-bottom: 0px;
  }
}
@media (max-width: 1169px) {
  .employee-detail-page-main-div-2 {
    display: none;
  }
}
@media (min-width: 1169px) {
  .employee-resp-contacts {
    display: none;
  }
}

.round-avatar {
  border-radius: 50%;
}

@media (max-width: 595px) {
  .resp-emp-details-card {
    display: flex;
    justify-content: center;
  }
}
