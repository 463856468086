.wearlay-display {
  font-size: 32px;
  font-weight: 700;
  color: var(--title-text);
}

.wearlay-headline-1 {
  font-size: 24px;
  font-weight: 700;
  color: var(--title-text);
}

.wearlay-headline-2 {
  font-size: 18px;
  font-weight: 700;
  color: var(--title-text);
}

.wearlay-subtitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--title-text);
}

.wearlay-paragraph {
  font-size: 14px;
  font-weight: 400;
  color: var(--title-text);
}

.wearlay-paragraph-bold {
  font-size: 14px;
  font-weight: 700;
  color: var(--title-text);
}

.wearlay-caption {
  font-size: 13px;
  font-weight: 400;
  color: var(--title-text);
}

.wearlay-label {
  font-size: 12px;
  font-weight: 400;
  color: var(--title-text);
}

.wearlay-label-bold {
  font-size: 12px;
  font-weight: 700;
  color: var(--title-text);
}
