.create-quotation-bg-color {
  background-color: white;
  /* max-height: 700px; */
  overflow-y: auto;
  padding: 10px;
}
.create-quotation-title {
  font-size: 24px;
  font-weight: 700;
}
.create-quotation-title-2 {
  font-size: 18px;
  font-weight: 700;
}
.create-quotation-border-main {
  border: 1px solid #e6ebec;
  padding: 10px;
}
.create-quotation-placeholder {
  /* font-size: 12px;
  font-weight: 700; */
  color: var(--placeholder);
}
.create-quotation-placeholder-2 {
  font-size: 14px;
  font-weight: 700;
}
.create-quotation-btn-color {
  background-color: var(--primary);
  color: white;
  margin-block: 10px;
  height: 44px;
}
.margin-block {
  margin-block: 10px;
}
.padding-bottom {
  padding-bottom: 44px;
}
.grand-total.ant-input[disabled] {
  background-color: var(--primary);
  color: white;
  font-size: 14px;
  font-weight: 700;
}
